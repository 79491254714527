body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: white;
}

.app-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Components/images/bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.731); /* Adjust the color and opacity as needed */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}


.navbar {
  background-color: rgba(82, 87, 92, 0.493) !important; /* Adjust the color and opacity as needed */
}
/* Add spacing between components */
.content > div {
  margin-bottom: 400px; /* Adjust this value to increase or decrease spacing */
  padding-top: 80px; /* This accounts for the fixed navbar */
}

/* Remove margin from the last component */
.content > div:last-child {
  margin-bottom: 0;
}