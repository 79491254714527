.search-bar {
    margin-bottom: 20px;
  }
  
  .category-name {
    color: #ff4500;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .menu-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 40px;
  }
  
  .menu-item-info {
    flex-grow: 1;
  }
  
  .menu-item-name {
    margin: 0;
    font-size: 18px;
  }
  
  .menu-item-description {
    margin: 5px 0;
    color: #969696;
    font-size: 14px;
  }
  
  .menu-item-price {
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .cart-container {
    position: sticky;
    top: 20px;
  }